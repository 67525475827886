import { Component, Input, OnInit } from "@angular/core";

import { UserChatGroupService } from "@app/shared/services/user-chat-group.service";
import { UserService } from "@app/shared/services/user.service";

import { UserExtended } from "@app/shared/models/entities/user/UserExtended";
import { TrainingTypeEnum } from "@app/shared/enums/training-type-enum";
import { UtilService } from "@app/shared/services/util.service";
import { TrainingLevelEnum } from "@app/shared/enums/TrainingLevelEnum";

@Component({
  standalone: true,
  selector: "chat-component",
  templateUrl: "./chat-component.component.html",
  styleUrls: ["./chat-component.component.scss"],
})
export class ChatComponentComponent implements OnInit {
  userChatGroups!: string[];
  window!: Window;
  @Input() userExtended!: UserExtended;

  constructor(
    private userChatGroupService: UserChatGroupService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.setupChat();
  }

  setupChat() {
    const params = {
      training_type: UtilService.isSchool24() ? TrainingTypeEnum.School24 : TrainingTypeEnum.OnlineSlagen,
    };

    this.userChatGroupService.getUserChatGroups(params).subscribe({
      next: (userChatGroups) => {
        this.userChatGroups = userChatGroups;

        if (this.userExtended.user.id) {
          this.initializeChat(this.userExtended.user.id, this.userExtended.user_restore_id);
        }

        this.setPropertiesAndRestore();
      },
      error: () => {
        this.userChatGroups = ["algemeen"];
        this.initializeChat(0, "");
      },
    });
  }

  initializeChat(userId: number, restoreId: string) {
    if (typeof window !== "undefined" && typeof (window as any).fcWidget !== "undefined") {
      (window as any).fcWidget.init({
        token: "494ed57d-ae9e-45c6-8917-f010a35ec138",
        host: "https://wchat.freshchat.com",
        externalId: userId,
        restoreId: restoreId,
        tags: this.userChatGroups,
        config: {
          disableNotifications: true,
          content: {
            placeholders: {
              csat_reply: "Plaats hier jouw opmerkingen",
            },
            actions: {
              csat_no: "Nee",
              csat_yes: "Ja",
              push_notify_yes: "Ja",
              push_notify_no: "Nee",
              csat_submit: "Verstuur",
            },
            headers: {
              chat: "Bijles chat",
              chat_help: "Stel hier al je vragen. We helpen je graag!",
              csat_question: "Hebben we je geholpen?",
              csat_no: "Nee",
              csat_yes: "Ja",
              push_notification: "Mis geen antwoorden! Sta push notificaties toe?",
              csat_yes_question: "Hoe vond je jouw ervaring met de bijles chat?",
              csat_no_question: "Wat hadden we beter kunnen doen?",
              csat_thankyou: "Bedankt voor je reactie!",
              csat_rate_here: "Submit your rating here",
            },
          },
        },
      });
    }
  }

  setPropertiesAndRestore() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    (window as any).fcWidget.user.get(function (resp: any) {
      const status = resp && resp.status;

      if (status !== 200) {
        (window as any).fcWidget.user.setProperties({
          firstName: self.userExtended.user.first_name,
          lastName: self.userExtended.user.last_name,
          email: self.userExtended.user.email,
          "Niveau ":
            self.userExtended.level === undefined
              ? "Nog niet gekozen"
              : self.userExtended.year + " " + TrainingLevelEnum[self.userExtended.level],
          "OS Rol ": self.userExtended.role,
          "S24 Rol ": self.userExtended.s24_role,
          "Leerling op ": self.userExtended.student_school_info,
          "Docent op ": self.userExtended.teacher_school_info,
        });
        (window as any).fcWidget.on("user:created", function (resp: any) {
          const status = resp && resp.status,
            data = resp && resp.data;
          if (status === 200) {
            if (data.restoreId) {
              self.userService.postRestoreId(data.restoreId).subscribe();
            }
          }
        });
      }
    });
  }
}
